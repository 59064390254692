import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, {
  LegacyRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import readXlsxFile, { Row } from 'read-excel-file';
import { getMarks } from './utils';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styles from './AdminMarks.module.scss';
import { AdminMarksItem } from './AdminMarksItem';
import { AdminMarksAverage } from './AdminMarksItem/AdminMarksAverage';
import ButtonGroup from '@mui/material/ButtonGroup';
import isSameDay from 'date-fns/isSameDay';
import { months } from './AdminMarksItem/AdminMarksItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import HistoryIcon from '@mui/icons-material/History';
import {
  useGetHelpAdviceQuery,
  useGetMarksQuery,
  useLazyGetHelpAdviceQuery,
  useUploadMarksMutation,
  useUploadMarksTxtMutation,
} from '../../../redux/GSApi';
import { API_URL } from '../../../utils/api';
import { KidMarks } from '../../../models/Marks/marks';
import { Loader } from '../../UI/Loader';
import { useHelpAssistant } from '../../../hooks/useHelpAssistant';
import Markdown from 'markdown-to-jsx';
import { IconButton, Skeleton } from '@mui/material';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

export const AdminMarks = () => {
  const [kids, setKids] = useState<KidMarks[] | null>(null);
  const [averageFilter, setAverageFilter] = useState(99);
  const {
    data: marksFromServer,
    isLoading,
    isSuccess,
    isError,
  } = useGetMarksQuery();
  const [triggerMarksTxtUpload, marksTxtUploadQueryState] =
    useUploadMarksTxtMutation();

  const {
    messages,
    loading: isAssistantLoading,
    getHelpAssistant,
  } = useHelpAssistant({
    type: 'marks',
  });

  // Высота меню
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setMenuHeight(menuRef?.current?.clientHeight ?? 0);
  });

  useEffect(() => {
    if (
      marksFromServer?.marksList.length &&
      marksFromServer?.marksList.length > 0
    ) {
      const newMarks = marksFromServer.marksList[0].marks;
      const lastMarks = marksFromServer.marksList[1]?.marks;

      if (lastMarks) {
        const marksWithNewLabel = newMarks.map((kid, kidIndex) => ({
          kid: kid.kid,
          subjects: kid.subjects.map((subject, subjectIndex) => ({
            subject: subject.subject,
            marks: subject.marks.map((mark, markIndex) => ({
              mark: mark.mark,
              month: mark.month,
              date: mark.date,
              new:
                mark.mark !==
                lastMarks[kidIndex]?.subjects[subjectIndex]?.marks[markIndex]
                  ?.mark,
            })),
            average: subject.average,
          })),
        }));

        setKids(marksWithNewLabel);
      } else {
        setKids(marksFromServer.marksList[0].marks);
      }
    }
  }, [marksFromServer]);

  // Загрузка файла
  const handleDeliveryFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files === null || event.target.files.length !== 1) {
      return;
    }

    await readXlsxFile(event.target.files[0]).then((rows) => {
      const kidsNew = getMarks({ excel: rows });

      setKids(kidsNew);

      kidsNew && triggerMarksTxtUpload({ marks: kidsNew }).unwrap();
    });
  };

  const kidsFiltered = useMemo(() => {
    return kids
      ?.map((kid) => {
        const subjects = kid.subjects.filter((subject) =>
          subject.average === '-'
            ? true
            : parseFloat(subject.average) < averageFilter
        );

        return { ...kid, isVisible: subjects.length > 0, subjects };
      })
      .filter((kid) => kid.isVisible);
  }, [kids, averageFilter]);

  const todayMarks = useMemo(() => {
    const today = new Date();
    const yesterday = new Date(new Date().setDate(today.getDate() - 1));

    return kids
      ?.map((kid) => {
        const result: {
          kid: string;
          marks: {
            subject: string;
            mark: string | number;
            month: string;
            date: string;
            isToday: boolean;
          }[];
        } = { kid: kid.kid, marks: [] };

        kid.subjects.map((subject) =>
          subject.marks.map((mark) => {
            const month = months[mark.month as string];
            const date = new Date(
              `${today.getFullYear()}-${month}-${mark.date}`
            );
            const isToday = isSameDay(today, date);
            const isYesterday = isSameDay(yesterday, date);

            if (isToday || isYesterday) {
              result.marks.push({ ...mark, subject: subject.subject, isToday });
            }
          })
        );

        return result;
      })
      .filter((kid) => kid.marks.length > 0);
  }, [kids, averageFilter]);

  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button component="label" variant="contained">
          <input
            type="file"
            accept=".xls,.xlsx"
            hidden
            onChange={handleDeliveryFileUpload}
          />
          Загрузить классный журнал (excel)
        </Button>
      </Grid>

      {!kids ? (
        <div className={styles.info__wrapper}>
          <div className={styles.info}>
            Загрузите отчёт "Распечатка классного журнала", чтобы увидеть
            оценки.
            <br />
            Обратите внимание, что АСУ РСО выдаёт отчёт в формате xls (это
            старый файл экселя, который похоронили ещё в 2007). Вам необходимо
            пересохранить этот отчёт в формате xlsx (Открываем файл в экселе -
            файл - сохранить как - Книга Excel (*.xlsx))
          </div>
          <div>{isLoading && <Loader />}</div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          {/* Меню */}
          <div className={styles.menu} ref={menuRef}>
            {kidsFiltered?.map((kid) => (
              <div
                key={kid.kid}
                className={styles.menu__kid}
                onClick={() => handleClickScroll(kid.kid.split(' ').join('-'))}
              >
                {kid.kid}
              </div>
            ))}
          </div>

          {/* Новые оценки */}
          <Accordion sx={{ mb: 3 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className={styles.todayMarks__header}>Новые оценки</div>
              <div className={styles.todayMarks__subheader}>
                оценки, полученные за последние 2 дня
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.todayMarks}>
                {todayMarks?.length === 0 ? (
                  <>Нет новых оценок за последние 2 дня</>
                ) : (
                  todayMarks?.map((item) => (
                    <div className={styles.todayMarks__row} key={item.kid}>
                      <div className={styles.todayMarks__name}>{item.kid}</div>
                      <div className={styles.todayMarks__marks}>
                        {item.marks.map((mark) => (
                          <AdminMarksItem
                            mark={mark}
                            key={`${mark.date} - ${mark.month} - ${mark.subject}`}
                            description={mark.subject}
                          />
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </AccordionDetails>
          </Accordion>

          <div className={styles.helpPanel}>
            <div className={styles.helpPanel__helpButton}>
              <Button
                startIcon={<HelpCenterOutlinedIcon />}
                variant="outlined"
                onClick={
                  messages.length > 0 || isAssistantLoading
                    ? undefined
                    : getHelpAssistant
                }
                disabled={messages.length > 0 || isAssistantLoading}
              >
                Помощь ассистента
              </Button>
            </div>
            {/* Фильтры */}
            <div className={styles.filters}>
              <ButtonGroup
                variant="outlined"
                aria-label="Фильтрация по оценкам"
              >
                <Button
                  onClick={() => setAverageFilter(2.5)}
                  variant={averageFilter >= 2.5 ? 'contained' : 'outlined'}
                >
                  Двойки
                </Button>
                <Button
                  onClick={() => setAverageFilter(3.5)}
                  variant={averageFilter >= 3.5 ? 'contained' : 'outlined'}
                >
                  Тройки
                </Button>
                <Button
                  onClick={() => setAverageFilter(4.5)}
                  variant={averageFilter >= 4.5 ? 'contained' : 'outlined'}
                >
                  Четверки
                </Button>
                <Button
                  onClick={() => setAverageFilter(99)}
                  variant={averageFilter >= 5 ? 'contained' : 'outlined'}
                >
                  Все оценки
                </Button>
              </ButtonGroup>
            </div>
            {isAssistantLoading && messages.length === 0 && (
              <div className={styles.helpPanel__loading}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
            )}
            {messages.length > 0 && (
              <div className={styles.helpPanel__message}>
                <Markdown>{messages.join('')}</Markdown>
              </div>
            )}
          </div>

          <div className={styles.kidList}>
            {/* Список детей и их оценок */}
            {kidsFiltered?.map((kid) => (
              <div className={styles.subjects__wrapper} key={kid.kid}>
                <div className={styles.kidname__wrapper}>
                  <div
                    className={styles.kidname}
                    style={{
                      top: menuHeight + 20,
                    }}
                  >
                    {kid.kid}
                  </div>
                </div>

                <div
                  className={styles.subjects}
                  id={kid.kid.split(' ').join('-')}
                  style={{
                    scrollMarginTop: menuHeight,
                  }}
                >
                  <div className={styles.subjects__header}>
                    <HistoryIcon />
                    {marksFromServer?.dateList?.map((date, index) => {
                      const dateObj = new Date(date);
                      return (
                        <Button
                          key={`${dateObj.toDateString()}-${kid.kid}-${index}`}
                          size="small"
                          variant="outlined"
                          onClick={() =>
                            marksFromServer?.marksList?.[index]?.marks &&
                            setKids(marksFromServer?.marksList?.[index]?.marks)
                          }
                        >
                          {format(dateObj, 'dd MMMM', { locale: ru })}
                        </Button>
                      );
                    })}
                  </div>
                  {kid.subjects.map((subject) => (
                    <div
                      key={`${kid}-${subject.subject}`}
                      className={styles.subjects__row}
                    >
                      <div className={styles.average}>
                        <AdminMarksAverage average={subject.average} />
                      </div>
                      <div className={styles.subjects__name}>
                        {subject.subject}
                      </div>
                      <div className={styles.marks}>
                        {subject.marks.map((mark) => (
                          <AdminMarksItem
                            key={`${mark.date}-${mark.month}-${mark.mark}-${kid.kid}`}
                            mark={mark}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Grid>
  );
};
